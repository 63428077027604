import { db } from "@/@core/firebase"
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore"
import EnvironmentEntity from "../entities/EnvironmentEntiry"

const useEnvironment = (platform: "blaze" | "apostaGanha" | "apostaGanha2") => {
  const getEnvironment = async (
    game: "crash" | "double",
    id: string
  ): Promise<EnvironmentEntity> => {
    const docRef = doc(db, `${platform}/${game}/environments/${id}`)

    const env = await getDoc(docRef)

    if (!env || !env.exists()) throw new Error("not exists")

    return {
      id: env.id,
      activated: env.data().activated,
      botKey: env.data().botKey,
      chatId: env.data().chatId,
      mode: env.data().mode ?? "full",
    }
  }

  const saveEnvironment = async (
    game: "crash" | "double",
    id: string,
    data: Omit<EnvironmentEntity, "id">
  ): Promise<boolean> => {
    console.log(`${platform}/${game}/environments/${id}`)
    console.log(data)
    const docRef = doc(db, `${platform}/${game}/environments/${id}`)

    await updateDoc(docRef, data)

    return true
  }

  return {
    saveEnvironment,
    getEnvironment,
  }
}

export default useEnvironment
